.linktextprovider {
    display: none;
}

.breadcrumb {
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.2s ease;
}

.breadcrumb:hover {
    color: #ff7606;
}

.warning {
    background-color: #FFD3D5;

    border-radius: 12px;
    border-color: #FF1923;
    border-width: 4;

    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    margin-top: 12px;
    margin-bottom: 12px;

    margin-left: 0px !important;
    margin-right: 0px !important;
}

.warning>table {
    inline-size: 100%;
}

.important {
    background-color: #e3f3ff;

    border-radius: 12px;
    border-color: #20589E;
    border-width: 4;

    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    margin-top: 12px;
    margin-bottom: 12px;

    margin-left: 0px !important;
    margin-right: 0px !important;
}

.important>table {
    inline-size: 100%;
}

.caution {
    background-color: #fffacf;

    border-radius: 12px;
    border-color: #ff8122;
    border-width: 4;

    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    margin-top: 12px;
    margin-bottom: 12px;

    margin-left: 0px !important;
    margin-right: 0px !important;
}

.caution>table {
    inline-size: 100%;
}

.note {
    background-color: #E8EAF6;

    border-radius: 12px;
    border-color: #ff8122;
    border-width: 4;

    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    margin-top: 12px;
    margin-bottom: 12px;

    margin-left: 0px !important;
    margin-right: 0px !important;
}

.note>table {
    inline-size: 100%;
}

// .td p {
//     display: inline-block;
// }

// .emphasis {
//     margin-left: 0.3rem;
// }

// li > * {
//     display: inline-block;
// }

.procedure li p {
    vertical-align: top;
}

.orderedlist {
    width: 97%;
    display: flex;
    flex-direction: column; 
}

@media (min-width: 1106px) {
    .itemizedlist {
        width: 100%;
        display: flex;
        flex-direction: column; 
    }
}

  @media (min-width: 1170px) {
    .orderedlist {
        width: 95%;
        display: flex;
        flex-direction: column; 
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .orderedlist {
        width: 90%;
        display: flex;
        flex-direction: column; 
    }
  }

.orderedlist .listitem p {
    vertical-align: top;
}

.itemizedlist .listitem p {
    vertical-align: top;
}

.listitem p, span {
    display: inline;
}

.MuiBox-root > p,
.MuiBox-root > span.emphasis {
  display: inline;
}

// .td p > .strong {
//     display: flex;
//     flex-direction: column;
// }

.informaltable tr {
    border: solid 1px #d3d3d3;
}

.informaltable,
.table-bordered {
    color: #000000;
    background-color: #ffffff;

    border-radius: 12px;
    border-color: #ff8122;
    border-width: 4;
    border-collapse: collapse;

    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    margin-top: 12px;
    margin-bottom: 12px;

    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.informaltable th,
.table-bordered th {
    padding-left: 0.4em;
    padding-right: 0.4em;
    border: 1px solid #c4c4c4;
}

.informaltable table,
.table-bordered table {
    border-collapse: collapse;
}

.informaltable td,
.table-bordered td {
    padding-left: 0.4em;
    padding-right: 0.4em;
    border: 1px solid #c4c4c4;
    vertical-align: center;
}

.informaltable:has(> * .mediaobject) {
    color: #20589E;
    background-color: #DBEBFF;
}

.informaltable:has(> * .inlinemediaobject) {
    color: #20589E;
    background-color: #DBEBFF;
}

.th:has(> .mediaobject) {
    border: none;
}

.th:has(> * .inlinemediaobject) {
    border: none;
}

.td:has(> .mediaobject) {
    border: none;
}

// .td:has(> * .emphasis) {
//     border: none;
// }

.mediaobject+.informaltable {
    color: #20589E;
    background-color: #DBEBFF;
}

.mediaobject+.informaltable .td {
    border: none;
}

.mediaobject td {
    border: none;
}

.inlinemediaobject td {
    border: none;
}

.informaltable tr:hover {
    background-color: #fffaf7;
}

body {
    font-family: system, -apple-system, BlinkMacSystemFont;
    font: -apple-system-body;
    margin-left: 16px;
    margin-right: 16px;

    /*    line-height: 1.2;*/
}

.voice-activated {
    background-color: #6868682e;
    border-radius: 8px;
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;

    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

img[alt^="["] {
    width: 32px;
 }

.underline {
    text-decoration: underline;
}

img {
    max-width: 100%;
}

div p {
    padding: 0;
    margin: 0;
}

p:not(tr p) {
    margin-bottom: 8px;
}

.navheader {
    display: none;
}

.navfooter {
    display: none;
}

.collapsable-content {
    overflow: hidden;

    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 6px;

    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.collapsable-content.active {
    background-color: #59cb367d;
    margin-top: 12px;
}

.role {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #EFEFEF;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
    margin-right: -16px;

    /* margin-bottom: 12px; */
    margin-top: 12px;

    font-weight: 600;

    border-bottom: 1.5px solid #E0E0E0;
}

.warning h3 {
    margin: 0;
    padding: 0;
}

.informaltable h3 {
    margin: 0;
    padding: 0;
}

.role.active {
    background-color: white;
    border-bottom: 0px solid #E0E0E0;
}

.collapsable-content.current {
    background-color: #59cb367d;
}

.role.current {
    background-color: #59cb367d;
}


.rule-section {
    font-size: 1.2em;
    font-weight: 700;
    padding-top: 1em;
}

.highlight {
    background-color: yellow;
    color: black;
}

h2.title {
    font-size: 1.4em;
    margin-top: 8px;
    margin-bottom: 4px;
}

h3.title {
    font-size: 1.2em;
    margin-top: 8px;
    margin-left: 0px;
    margin-bottom: 4px;
    padding: 0px;
}

.zoom {
    transform: scale(1.8);
    transition: transform 0.3s ease;
}

.zoom-out {
    transition: transform 0.3s ease;
}

.image-modal {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    border: 2px solid #000;
    box-shadow: 24;
    object-fit: cover;
    object-position: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}