.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.app-side-bar {
  position: relative;
  height: 100%;
  width: 250px;
  overflow: hidden;
  background-color: #25272c;
  color: #fff;
  transition: all 0.5s ease;
}

.app-side-bar-collapse {
  position: relative;
  height: 100%;
  width: 30px;
  overflow: hidden;
  background-color: #25272c;
  color: #fff;
  transition: all 0.5s ease;
}

.app-side-bar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.content-right-layout {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.page-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.collapse-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #000000;
  border-radius: 9999px;
  z-index: 1200;
  width: 25px;
  height: 25px;
  border: 1px solid #d1d5db;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.collapse-button:hover {
  background-color: #f57c00;
  color: #ffffff;
  border: none;
}

@media (max-width: 1440px) {
  .collapse-button {
    display: flex;
  }
}

@media (max-width: 1200px) {
  .collapse-button {
    display: none;
  }
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s all ease-in-out;
}

.module-collapse-button {
  left: 39%;
  position: absolute;
  top: 90px;
  z-index: 99;
}

@media (min-width: 900px) {
  .module-collapse-button {
    left: 46.5%;
    position: absolute;
    top: 90px;
    z-index: 99;
  }
}

@media (min-width: 1000px) {
  .module-collapse-button {
    left: 48%;
    position: absolute;
    top: 90px;
    z-index: 99;
  }
}

@media (min-width: 1200px) {
  .module-collapse-button {
    left: 40%;
    position: absolute;
    top: 90px;
    z-index: 99;
  }
}

.module-collapse-button-collapsed {
  left: 10px;
  position: absolute;
  top: 80px;
}