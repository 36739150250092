.not-acknowledged-number {
  position: absolute;
  top: 15px;
  left: 35px;
  font-size: 12;
  font-weight: 600;
  color: #727272;
  padding: 0px;
}

@media screen and (max-width: 430px) {
  .not-acknowledged-number {
    position: absolute;
    top: 15px;
    left: 23px;
  }
}

.not-acknowledged-small-number {
  position: absolute;
  top: 15px;
  left: 39px;
  font-size: 12;
  font-weight: 600;
  color: #727272;
  padding: 0px;
}

@media screen and (max-width: 430px) {
  .not-acknowledged-small-number {
    position: absolute;
    top: 15px;
    left: 23px;
  }
}

@media (min-width: 300px) {
  .accordion-values {
    display: flex;
    flex-direction: column;
  }

  .accordion-values-location {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 620px) {
  .accordion-values {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .accordion-values-location {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 800px) {
  .accordion-values {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}